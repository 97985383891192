.main__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  flex-direction: column;
}
.main__container {
  display: flex;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1900px;
}
.form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 50px;
  padding: 10px;
  width: 100%;
  max-width: 450px;
}

.input_wrapper {
  display: flex;
  width: 100%;
}
.img {
  width: 18px;
  padding-right: 5px;
}
.select {
  min-width: 120px !important;
}
.secondValue {
  font-style: italic !important;
}
.first_input {
  width: 100%;
}
.second_input {
  color: black !important;
  pointer-events: none;
  width: 100%;
}
.current_price {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 15px 0 15px 0;
  border: 1px rgba(0, 0, 0, 0.37) solid;
  border-radius: 5px;
}
.minimal {
  display: flex;
  justify-content: center;
  border: 1px rgba(0, 0, 0, 0.37) solid;
  border-radius: 5px;
  margin-bottom: 10px;
}
.minimal_red {
  display: flex;
  justify-content: center;
  border-radius: 5px;
  margin-bottom: 10px;
  border: 1px rgba(201, 4, 4, 0.603) solid;
  color: red;
}
