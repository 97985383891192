.footer_wrapper {
  display: flex;
  width: 100%;
  min-height: 300px;
  background-color: #323232;
  justify-content: center;
  align-items: center;
  color: white;
}
.footer_container {
  display: flex;
  width: 100%;
  max-width: 1100px;
  justify-content: space-between;
  padding: 10px;
}
.rules:hover {
  cursor: pointer;
  color: #ff9900;
}
.left_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 200px;
}
.right_container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 200px;
}
.support_div {
  display: flex;
  justify-content: space-evenly;
  margin-right: 50px;
  color: white;
  h3 {
    color: white;
  }
}

@media (max-width: 750px) {
  .footer_container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .support_div {
    margin: 0;
  }
  .right_container {
    justify-content: space-around;
    flex-direction: column;
  }
}
