.container {
  width: 100%;

  background-image: url("../../assets//img/main_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: start;
  align-items: center;
}
.main {
  width: 100%;
  max-width: 1200px;
  min-height: 100vh;
  backdrop-filter: blur(140px);
  background: linear-gradient(
    126.6deg,
    hsla(0, 0%, 100%, 0.12) 28.69%,
    hsla(0, 0%, 100%, 0)
  );
  height: 100%;
  display: flex;
  justify-content: end;
}
.main_div {
  display: flex;
  width: 100%;
  max-width: 800px;
  flex-direction: column;
  padding: 20px;
  margin-top: 80px;
  .main_h1 {
    color: white;
    font-size: 40px;
  }
  .main_p {
    margin-top: 25px;
    color: white;
    font-size: 17px;
  }
}
.exhange_price {
  width: 100%;
  margin: 50px 0 50px 0;
  color: #ff9900;
}
.form_div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
}

.form {
  display: flex;
  width: 100%;
  flex-direction: column;

  .input_div {
    display: flex;
    width: 100%;
    flex-direction: column;
    height: 120px;
    text-align: start;
    justify-content: center;
  }
  .first_container {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .personal_data {
    margin: 20px 0 20px 0;
    .personal_data_h2 {
      color: #ff9900;
      font-size: 33px;
    }
    p {
      color: white;
      font-size: 17px;
      margin-top: 10px;
    }
  }

  .second_container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    .email_input {
      width: 100% !important;
      max-width: 320px !important;
      margin-top: 15px !important;
      border: seashell solid 1px !important;
      display: flex !important;

      input {
        color: white !important;
      }
    }
    .wallet_input {
      width: 100% !important;
      max-width: 400px !important;
      margin-top: 15px !important;
      border: seashell solid 1px !important;
      display: flex !important;

      input {
        color: white !important;
      }
    }
  }
}
.input_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 320px;

  h2 {
    color: #ff9900;
    font-size: 33px;
  }
}

.input {
  width: 100%;
  max-width: 350px;
  margin-top: 15px !important;
  border: seashell solid 1px !important;
  display: flex !important;
  color: white !important;
}
.txt_wrapper {
  display: flex;
  width: 100%;
  max-width: 300px;
  color: white;
}
.upDown_container {
  position: relative;
  top: 100px;
  width: 60px;
  margin: 5px;
  img {
    width: 100%;
    transform: rotate(90deg);
  }
}
.btn_div {
  background-color: #ff9900 !important;
  width: 320px !important;
  margin-left: 50px;
}
.btn_form {
  margin-top: 50px;
  display: flex;
  width: 100%;
}
.MenuItem {
  border: rgba(48, 48, 48, 0.315) solid 1px !important;
  margin: 2px !important;
  p {
    margin-left: 5px !important;
  }
  h4 {
    margin-left: 5px !important;
    padding-left: 5px !important;
    border-left: #ff9900 1px solid;
  }
}
.img {
  width: 28px;
  padding-right: 5px;
}
#right_select {
  opacity: 0;
}
#test {
  display: flex !important;
  color: white !important;
  p {
    margin-left: 5px !important;
  }
  h4 {
    margin-left: 5px !important;
    padding-left: 5px !important;
    border-left: #ff9900 1px solid;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
#fInput {
  color: white !important;
}
#sInput {
  color: white !important;

  pointer-events: none !important;
}

.updown:hover {
  border: #ff99009a solid 1px;
  border-radius: 5px;
  cursor: pointer;
}
.checkbox_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-left: 50px;
}

@media (max-width: 750px) {
  .first_container {
    flex-direction: column;
  }
  .input_div {
    justify-content: center;
    align-items: center;
  }
  .main_div {
    text-align: center;
  }
  .upDown_container {
    top: 0;

    img {
      transform: rotate(180deg);
    }
  }
  .second_container {
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .checkbox_wrapper {
    margin: 0 0 15px 0;
  }
  .btn_form {
    align-items: center;
    flex-direction: column-reverse;
    margin-bottom: 50px;
    justify-content: center;
  }
  .btn_div {
    margin: 0;
  }
  .first_container {
    justify-content: center;
    align-items: center;
  }
}
