.page_wrapper {
  width: 100vw;
  display: flex;
  justify-content: center;
  background-image: url("../../assets//img/main_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
}
.page_container {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  min-height: 100vh;
  backdrop-filter: blur(140px);
  background: linear-gradient(
    126.6deg,
    hsla(0, 0%, 100%, 0.12) 28.69%,
    hsla(0, 0%, 100%, 0)
  );
}
.btn_disables {
  background-color: rgba(128, 128, 128, 0.568) !important;
  width: 320px !important;
}
.title {
  color: white;
  font-size: 40px;
}
.row_container {
  margin-top: 100px;
  padding: 10px;
  border-top: 1px white solid;
  border-bottom: #ff9900 solid 1px;
}
.main_div {
  display: flex;
  width: 100%;
  max-width: 800px;
  flex-direction: column;
  padding: 20px;
  margin-top: 80px;
  .span {
    color: white;
    margin-top: 15px;
  }
  .description {
    color: white;
    padding-top: 30px;
  }
}
.row_wrapper {
  display: flex;
  width: 100%;
  margin: 5px;
  justify-content: center;
  align-items: center;

  div {
    display: flex;
  }
  .row_title {
    color: #ff9900;
    margin-right: 5px;
  }
  .row_info {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.btn_div {
  background-color: #ff9900 !important;
  width: 320px !important;

  color: white !important;
}
.h2_wallet {
  color: #ff9900;
  margin-top: 12px;
  padding: 10px 5px 10px 5px;
  border-bottom: #ff9900 1px solid;
}
.crypto_img {
  width: 22px;
  margin: 0 5px 0 5px;
}
.final_step {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.411);
  padding: 10px 5px 25px 5px;
  margin-top: 40px;
}

.status_div {
  color: white;
}
.checkbox_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-left: 50px;
}
.btn_form {
  margin-top: 50px;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.adress {
  color: white;
}
.copy_ptn {
  color: #ff9900 !important;
  border-color: #ff9900 !important;
}
.attention {
  color: white;
  text-align: center;
  margin-top: 10px;
}
@media (max-width: 750px) {
  .row_wrapper {
    flex-direction: column;
  }
  .h2_wallet {
    font-size: 15px;
  }
  .row_title {
    margin: 10px;
  }
  .checkbox_wrapper {
    margin: 5px 0 0 0;
  }
  .description {
    text-align: center;
  }
}
