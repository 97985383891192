.header_wrapper {
  display: flex;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
  width: 100%;
  justify-content: center;
  padding: 10px;
}
.header_container {
  display: flex;
  width: 100%;
  max-width: 1100px;
}
.logo_div {
  display: flex;
  width: 300px;
  img {
    width: 100%;
    border: 10px;
  }
}
.header_container {
  display: flex;
  width: 100%;
}
