.about_wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  padding: 15px;
}
.about {
  display: flex;

  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 1100px;
}
.title {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 50px;
  h1 {
    color: #ff9900;
    font-size: 50px;
  }
  p {
    display: flex;
    width: 100%;
    font-size: 25px;
    margin-top: 25px;
    max-width: 600px;
  }
}
.card_container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 80px 0 0 0;
  flex-wrap: wrap;
}
.card {
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 300px;
  justify-content: space-around;
}

.card_txt {
  display: flex;
  flex-direction: column;
  height: 110px;
  justify-content: space-between;
}
.txt_about {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-top: 180px;
}
.about_container {
  display: flex;
  width: 100%;
  max-width: 800px;
  justify-content: space-between;
}
.txt_about_item {
  margin-bottom: 20px;
  h1 {
    font-size: 35px;
    margin-bottom: 10px;
  }
}
@media (max-width: 750px) {
  .txt_about {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .title {
    text-align: center;
  }
  .about_container {
    flex-direction: column;
    text-align: center;
  }
  .card {
    flex-direction: row;
    width: 100%;
    height: 150px;
    margin-top: 20px;
    img {
      width: 80px;
      margin-right: 10px;
    }
  }
}
