*,
html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family:Arial, Helvetica, sans-serif;
}

.App {
  position: relative;
}
