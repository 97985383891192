.page_wrapper {
  width: 100vw;
  display: flex;
  justify-content: center;
  background-image: url("../../assets//img/main_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
}
.page_container {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  min-height: 100vh;
  backdrop-filter: blur(140px);
  background: linear-gradient(
    126.6deg,
    hsla(0, 0%, 100%, 0.12) 28.69%,
    hsla(0, 0%, 100%, 0)
  );
}
.title {
  color: white;
  font-size: 40px;
}
.row_container {
  margin-top: 80px;
}
.main_div {
  display: flex;
  width: 100%;
  max-width: 800px;
  flex-direction: column;
  padding: 20px;
  margin-top: 80px;
  p {
    color: white;
    margin-top: 40px;
    border-top: #ff9900 solid 1px;
    padding-top: 30px;
  }
}
.row_wrapper {
  display: flex;
  width: 100%;
  border: solid 1px white;
  margin-top: 20px;
  border-radius: 4px;
  height: 50px;
  justify-content: center;
  align-items: center;
  .row_title {
    color: #ff9900;
    margin-right: 5px;
  }
  .row_info {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.btn_div {
  background-color: #ff9900 !important;
  width: 320px !important;

  color: white !important;
  margin-top: 40px !important;
}
.crypto_img {
  width: 22px;
  margin: 0 5px 0 5px;
}

@media (max-width: 750px) {
  .row_wrapper {
    flex-direction: column;
    justify-content: center;
  }
  .btn_container {
    display: flex;
    width: 100%;
    justify-content: center;
  }
}
