.loader_wrapper {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  text-align: center;
}
.loader_overlay {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
  color: white;
  font-size: 20px;
  backdrop-filter: blur(140px);
  background: linear-gradient(
    126.6deg,
    hsla(0, 0%, 100%, 0.12) 28.69%,
    hsla(0, 0%, 100%, 0)
  );

  padding: 10px;
}
.link {
  color: #ff9900;
}
